import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {BiLogOut} from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import { MyContext } from '../../context/Context';

export const MaxLifeNavbar = () => {
  const { hr, setHr,setEmployee } = useContext(MyContext);

  const nav = useNavigate()
  const handleLogout = ()=>{
    localStorage.removeItem('chatLoginMaxlife');
    toast.error('Logout!');
    setTimeout(() => {
        nav('/login');
    }, 3000);
  }

  const handleToggle = () => {
    console.log('toggle')
    setHr(!hr);
    setEmployee(null)
  };



  return (
    <>
      <div className='h-[80px] flex justify-between items-center p-[20px] relative ' >
        
        <div className='h-[25px] laptop:h-[50px] ' >
          <img className='w-[100%] h-[100%] object-contain ' src={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTm6GA9oHgg2zGQmYAgG1i4AIM_mEqjI0Mztg&s'} alt="Personate chatbot logo" />
        </div>

        <div className='flex gap-x-4 items-center ' >
          
          <div className='flex gap-x-2 items-center ' >
            <div className='' >Coach</div>
            <div className='mt-1 ' >
              <label className="relative inline-flex items-center">
                <input
                  type="checkbox"
                  className="sr-only peer"
                  checked={hr}
                  onChange={handleToggle}
                />
                <div
                  className={`w-11 h-6 bg-gray-200 rounded-full peer  
                  peer-checked:bg-primary transition-all duration-300`}
                ></div>
                <div
                  className={`absolute left-[4px] top-[2.5px] w-5 h-5 bg-white rounded-full border border-gray-300
                  peer-checked:translate-x-5 peer-checked:border-transparent transition-transform duration-300`}
                ></div>
              </label>
            </div>
            <div>Hr</div>
          </div>

          <div
            onClick={handleLogout}
            className='text-[22px] laptop:text-[24px] cursor-pointer text-black'
            data-tooltip-id="logoutTooltip"
          >
            <BiLogOut />
          </div>
        </div>


        {/* Tooltip container */}
        <Tooltip
          id="logoutTooltip" // Match the ID
          place="bottom"
          type="dark"
          effect="solid"
          className="custom-tooltip"
        >
          Logout
        </Tooltip>
      
      </div>
    <ToastContainer/>
    </>
  )
}
