import React from 'react'
import { Navigate } from 'react-router-dom';

export const PrivateRoutes = ({ children }) => {
    const isAuthenticated = localStorage.getItem('chatLogin');
    const maxlifeauth = localStorage.getItem('chatLoginMaxlife')

    if(maxlifeauth){
        return <Navigate to='/maxlife' />
    }

    return isAuthenticated ? children : <Navigate to="/login" />;
  
}
