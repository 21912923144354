  import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
  import { useSpring, animated } from '@react-spring/web';
  import { IoMdSend } from 'react-icons/io';
  import { FaChevronDown, FaHistory } from 'react-icons/fa';
  import { AiOutlineHistory, AiOutlineMessage } from 'react-icons/ai';
  import { io } from 'socket.io-client';
  import ChatBubble from './Chatbubble';
  import { Debouncing } from './Debouncing';
  import { debounce } from '../../utils/debounce';
  import {motion} from 'framer-motion'
  import { useMediaQuery } from 'react-responsive';
  import { HiOutlineSpeakerWave, HiOutlineSpeakerXMark } from 'react-icons/hi2';
  import { FileCard } from '../cards/FileCard';
  import { RateCard } from '../cards/RateCard';
  import { DateCard } from '../cards/DateCard';
  import { NumberCard } from '../cards/NumberCard';
  import { ChatLoading } from './ChatLoading';
  import { getCurrentTime } from '../../utils/funtions';
  import { SpeechRecognisation } from './SpeechRecognisation';
  import { MaxLifeNavbar } from './MaxLifeNavbar';
import { MyContext } from '../../context/Context';


// const socket = io("https://reply.personate.ai", {
//   path: "/reply_maxlife_coach/sockets",
// });
// console.log(socket)

export const Body = () => {
    const [message, setMessage] = useState('');
    const [isRecording,setIsRecording] = useState(true)
    const [audioSupport,setAudioSupport] = useState(true)
    const [allMessageData, setAllMessageData] = useState([]);
    const [isConnected, setIsConnected] = useState(false);
    const [socket,setSocket] = useState(null)
    const [suggestQuestionPrompts, setSuggestQuestionPrompts] = useState([]);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const chatContainerRef = useRef(null);
    const [dynamicVideoVisibile,setDynamicVideoVisibile] = useState(false)
    const [muted,setMuted] = useState(false)
    const videoRef = useRef(null)
    const containerRef = useRef(null);
    const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
    const [messageLoading,setMessageLoading] = useState(false)
    const [cardType,setCardType] = useState({
      rateCard : false,
      dateCard : false,
      fileCard : false,
      numberCard : false
    })

    const [cardDetails,setCardDetails] = useState({
      heading : '',
      subHeading : ''
    })
    const [showCard,setShowCard] = useState(false)
    const [videoLink,setVideoLink] = useState('')

    const {hr,employee,setEmployee } = useContext(MyContext)

    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });
    const isLaptopScreen = useMediaQuery({ query: '(min-width: 1025px) and (max-width: 1440px)' });
  

    useEffect(() => {
      // Create the Socket.IO connection
      const newSocket = io("https://reply.personate.ai", {
        path: `${hr?'/reply_maxlife_hr':'/reply_maxlife_coach'}/sockets`,
      });
  
      setSocket(newSocket);
      setAllMessageData([])
  
      // Clean up the connection when the component is unmounted
      return () => {
        newSocket.disconnect();
      };
    }, [hr]);



    useEffect(() => {
      if(socket){
        socket.on('connect', () => {
          setIsConnected(socket.connected);
        });

        socket.on('disconnect', () => {
          setIsConnected(socket.connected);
        });

        socket.on('join', (data) => {
        });

        socket.on('chat', (data) => {
          if(data?.video){
            if(data?.video == videoLink){
              handlePlayVideo()
            }else{
              setVideoLink(data?.video)
            }
            
          }

          if(data?.type == "user"){
            setShowCard(true)
            if(data.meta.card_type == "rate_card"){
              setCardType({
                rateCard : true,
                dateCard : false,
                fileCard : false,
                numberCard : false
              })
              setCardDetails(
                {
                  heading : data.meta.amount,
                  subHeading : data.meta.text
                }
              )
            }else if(data.meta.card_type == "date_card"){
              setCardType({
                rateCard : false,
                dateCard : true,
                fileCard : false,
                numberCard : false
              })
              setCardDetails(
                {
                  heading : data.meta.date,
                  subHeading : data.meta.text
                }
              )

            }else if(data.meta.card_type == "number_card"){
              setCardType({
                rateCard : false,
                dateCard : false,
                fileCard : false,
                numberCard : true
              })
              setCardDetails(
                {
                  heading : data.meta.number,
                  subHeading : data.meta.text
                }
              )

            }else if(data.meta.card_type == "file_card"){
              setCardType({
                rateCard : false,
                dateCard : false,
                fileCard : true,
                numberCard : false
              })
              setCardDetails(
                {
                  heading : data.meta.text,
                  subHeading : data.meta.link
                }
              )

            }
          }
          if(data.sid == "Personate"){
            setMessageLoading(false)
            // if(data.query == "How is everything going with you at Max Life Insurance ?" || data.query == "How were your first 30 days at Max Life Insurance ?"){
              
            // }else{
            //   setAllMessageData((prevMessages) => [...prevMessages, { ...data, type: 'chat', isMine: false }]);
            // }
            if(data?.message == 'conversation_complete'){
              setEmployee(null)
            }
            setAllMessageData((prevMessages) => [...prevMessages, { ...data, type: 'chat', isMine: false }]);
          }
          
        });

        return () => {
          socket.off('connect');
          socket.off('disconnect');
          socket.off('join');
          socket.off('chat');
        };
      }
    }, [socket]);


    useEffect(() => {
      const updateSize = () => {
        if (containerRef.current) {
          setContainerSize({
            width: containerRef.current.offsetWidth,
            height: containerRef.current.offsetHeight
          });
        }
      };

      updateSize(); // Initial size update
      const resizeObserver = new ResizeObserver(updateSize);
      if (containerRef.current) {
        resizeObserver.observe(containerRef.current);
      }

      return () => {
        resizeObserver.disconnect();
      };
    }, [containerRef.current]);


    const handlePlayVideo = ()=>{
      if(videoRef.current){
        videoRef.current.play()
      }
    }

    const handlePauseVideo = ()=>{
      setDynamicVideoVisibile(false)
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }

    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
      }
    }, [allMessageData]);

    const handleReset = ()=>{
      setEmployee(null)
      setAllMessageData([])
      setMessageLoading(false)
    }


    const debouncedHandleInput = useCallback(
      debounce((value) => {
        if(value == false){
          setSuggestQuestionPrompts([])
        }
        else if(value?.length > 0 ) {
          setSuggestQuestionPrompts([
            "What is the first step in managing time effectively?",
            "How can planning backwards help in time management?",
            "How can effective communication enhance leadership?",
            "What are the phases of career transition?",
          ]);
        } else {
          setSuggestQuestionPrompts([]);
        }
      }, 700),
      []
    );

    const handleSend = useCallback((value) => {
      debouncedHandleInput(false);
      setDynamicVideoVisibile(false)
      setVideoLink('')
      handlePauseVideo()
      const trimmedMessage = value?.trim() || message.trim();
      
      if (trimmedMessage.length) {
        socket.emit('chat', trimmedMessage);
        if(value?.length>0){
          setAllMessageData((prevMessages) => [...prevMessages, { message:value, type: 'chat', isMine: true }]);
        }else{
          setAllMessageData((prevMessages) => [...prevMessages, { message:message, type: 'chat', isMine: true }]);
        }
        setMessageLoading(true)
        setMessage('');
      }
    }, [message]);

    const handleSuggestion = (value)=>{
      handleSend(value)
    }

    const handleEmployee = (value)=>{
      
      if(value=='new'){
        setEmployee("new")
        socket.emit('chat', "new_employ");
      }else if(value=='old'){
        setEmployee('old')
        socket.emit('chat', "old_employ");
      }
    }
    const handleChange = (event) => {
      const { value } = event.target;
      setMessage(value);
      debouncedHandleInput(value);
    };

    const handleVideoLoaded = () => {
      setDynamicVideoVisibile(true);
    };

    if(!audioSupport){
      console.log("Audio not supported")
    }


    const memoizedAllMessages = useMemo(() => allMessageData.map((data, i) => (
      <React.Fragment key={i}>
        <ChatBubble  isMine={data.sid == "Personate"?false:true} time={getCurrentTime()} message={data.message} video={data?.video} />

      </React.Fragment>
    )), [allMessageData]);

    const getWidth = () => {
      if (isChatOpen) {
        if (isSmallScreen) return containerSize.width * 0.5; // 40% of container width
        if (isLaptopScreen) return containerSize.width * 0.4; // 50% of container width
        return containerSize.width * 0.4; // 60% of container width
      }
      return 40; // Fixed width when not open
    };

    const getHeight = () => {
      if (isChatOpen) {
        if (isSmallScreen) return containerSize.height * 0.96; // 96% of container height
        if (isLaptopScreen) return containerSize.height * 0.97; // 96% of container height
        return containerSize.height * 0.97; // 96% of container height
      }
      return 40; // Fixed height when not open
    };

    const animationProps = useSpring({
      height: getHeight(),
      width: getWidth(),
      config: { duration: 200 }
    });

    const handleCross = ()=>{
      setShowCard(false)
      setCardType({
        rateCard : false,
        dateCard : false,
        fileCard : false,
        numberCard : false
      })
    }

    const getTranscript = (message) =>{
      handleSend(message)
    }



    return (
      <>
      <MaxLifeNavbar/>
      <div className='w-[96%] mx-auto font-redHat '>
        {
          showCard && (
            <motion.div
              initial={{ x: '100vw' }}
              animate={{ x: 0 }}
              transition={{ type: 'spring', stiffness: 50 }}
              className="absolute top-[100px] laptop:top-1/2 right-[10%] transform laptop:-translate-y-1/2 z-50">
          {
            cardType.rateCard?<RateCard handleCross={handleCross} heading={cardDetails.heading} subHeading={cardDetails.subHeading} />:cardType.numberCard?<NumberCard handleCross={handleCross} heading={cardDetails.heading} subHeading={cardDetails.subHeading} />:cardType.fileCard?<FileCard handleCross={handleCross} heading={cardDetails.heading} subHeading={cardDetails.subHeading} />:cardType.dateCard?<DateCard handleCross={handleCross} heading={cardDetails.heading} subHeading={cardDetails.subHeading} />:<div></div>
          }
        </motion.div>
          )
        }

        <div ref={containerRef} className='overflow-hidden w-[100%] tablet:w-[90%] laptop:w-[60%] rounded-3xl drop-shadow-2xl mx-auto relative group z-30'>

          {/* STATIC VIDEO */}
          <video
            autoPlay
            muted
            className={`mx-auto w-[100%] h-[100%] ${dynamicVideoVisibile?'hidden':'block'}`}
            loop
            src={"/maxLifeBackupVideo.mp4"}
          ></video>

          
          {/* DYNAMIC VIDEO */}
          <video
            ref={videoRef}
            onEnded={handlePauseVideo}
            muted={muted}
            autoPlay
            onLoadedMetadata={handleVideoLoaded}
            className={`mx-auto w-[100%] h-[100%] ${dynamicVideoVisibile?'block':'hidden'} `}
            src={videoLink}
          ></video>

          {/* MUTE UNMUTE BUTTON */}
          <div onClick={()=>setMuted(!muted)} className={`${dynamicVideoVisibile?'flex':'hidden'} transition-all ease-in-out duration-300 bg-black backdrop-blur-[20px] bg-opacity-20 cursor-pointer text-white text-[19px] rounded-full h-[35px] w-[35px] justify-center items-center absolute top-2 left-2 z-30 `} >{muted?<HiOutlineSpeakerXMark />:<HiOutlineSpeakerWave />}</div>

          {
            hr && employee!=null && <div onClick={handleReset} className='bg-black backdrop-blur-[20px] bg-opacity-20 cursor-pointer text-white text-[19px] rounded-2xl h-[40px] w-[40px] flex justify-center items-center absolute bottom-[7px] right-[55px] z-30 ' ><AiOutlineHistory /></div>
          }

          <animated.div
            style={{ ...animationProps, scrollBehavior: 'smooth' }}
            ref={chatContainerRef}
            className='transition ease-in-out duration-300 absolute bottom-1 laptop:bottom-2 right-1 laptop:right-2 overflow-y-auto text-[30px] bg-black rounded-2xl bg-opacity-20 z-50 scrollbar-hide backdrop-blur-lg border border-gray-600 border-opacity-50'
          >
            {isChatOpen ? (
              <div className='relative'>
                
                <div className='flex justify-between px-[10px] bg-opacity-90 sticky top-0 items-center h-[40px] text-[14px] text-white font-[500] bg-black'>
                  <div></div>
                  <div>Messages</div>
                  <div className='cursor-pointer' onClick={() => setIsChatOpen(false)}><FaChevronDown /></div>
                </div>

                <div className='p-[10px] h-[100%] '>
                  {allMessageData.length === 0 && (
                    <div className='grid h-[100%] place-content-center'>
                      <p className="bg-clip-text font-[500] text-[20px] text-transparent drop-shadow-2xl bg-gradient-to-b from-white/80 to-white/20">
                        No chat history
                      </p>
                    </div>
                  )}

                  <div style={{scrollBehavior: 'smooth'}} >
                    {allMessageData.length > 0 && memoizedAllMessages}
                    {
                      messageLoading && (<ChatLoading/>)
                    }
                  </div>
                </div>
              </div>
            ) : (
              <div onClick={() => setIsChatOpen(true)} className='text-white h-[100%] flex justify-center items-center cursor-pointer text-[19px]'><AiOutlineMessage /></div>
            )}
            
          </animated.div>
        </div>

        {/* INPUT BOX */}
        <div className='relative bg-black mt-[20px] '>
          {
            !hr && <Debouncing suggestQuestionPrompts={suggestQuestionPrompts} setSuggestQuestionPrompts={setSuggestQuestionPrompts} message={message} setMessage={setMessage} handleSend={handleSend} />
          }
          <div className={`absolute top-0 z-50 left-1/2 transform -translate-x-1/2 w-[98%] laptop:w-[50%] mx-auto `}>
            {
              <div className={`${employee==null && hr?'hidden':'block'} z-50 bg-black bg-opacity-40 w-[100%] flex p-[8px] ${suggestQuestionPrompts?.length > 0 && !hr ? 'rounded-b-[15px]' : 'rounded-full'} `}>
              <input
                value={message}
                onChange={handleChange}
                type='text'
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSend();
                  }
                }}
                className='z-50 flex-1 mx-[10px] bg-transparent focus:outline-none text-white font-[500] placeholder:text-white placeholder:text-[14px] placeholder:font-[400]'
                placeholder='Type your message...'
              />

              {
                message?.length==0 && audioSupport && isRecording?<SpeechRecognisation setAudioSupport={setAudioSupport} getTranscript={getTranscript} />:<div onClick={()=>handleSend()} className='flex justify-center cursor-pointer rounded-full bg-primary items-center text-[19px] p-[7px] text-white'>
                  <IoMdSend />
                </div>
              }
              
            </div>
            }
            
            <div className={`${(hr && employee==null)?'':'hidden'} flex justify-center gap-x-4 mt-3 `} >
              <button className={`block text-[15px] px-[10px] py-[5px] rounded-lg tracking-wider  
                ${employee=='old'?'text-white bg-slate-800 border-0 ':'text-slate-800 border border-slate-800 hover:bg-slate-100'} `} onClick={()=>handleEmployee('old')} >Old Employee</button>

              <button className={`block text-[15px] px-[10px] py-[5px] rounded-lg tracking-wider ${employee=='new'?'text-white bg-slate-800 border-0 ':'text-slate-800 border border-slate-800 hover:bg-slate-100'}  `} onClick={()=>handleEmployee('new')} >New Employee</button>
            </div>

          </div>
        </div>
      </div>
      </>
    );
  };
