import React, { createContext, useState } from 'react';

// Create the context
export const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
    const [hr, setHr] = useState(false);
    const [employee,setEmployee] = useState(null)
  
    return (
      <MyContext.Provider value={{ hr, setHr,employee,setEmployee}}>
        {children}
      </MyContext.Provider>
    );
  };
  