import React from 'react'
import { Navbar } from '../components/shared/Navbar'
import { Body } from '../components/homepage/Body'
import { BackgroundGradientAnimation } from '../components/ui/background-gradient-animation';
export const HSBC = () => {

  return (
    
    <div className='' >
      <BackgroundGradientAnimation>
          <Body/>
      </BackgroundGradientAnimation>
    </div>
  )
}
