import React from 'react'
import { Body } from '../components/Maxlife/Body'

export const MaxLife = () => {
  return (
    <div>
      <Body/>
    </div>
  )
}
