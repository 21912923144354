import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { Login } from '../pages/Login'
import { PrivateRoutes } from './PrivateRoutes'
import { MaxLife } from '../pages/MaxLife'
import { HSBC } from '../pages/HSBC'
import { PrivateRoutesMaxlife } from './PrivateRouteMaxlife'

export const AllRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<PrivateRoutes><HSBC/></PrivateRoutes>} ></Route>
        <Route path='/maxlife' element={<PrivateRoutesMaxlife><MaxLife/></PrivateRoutesMaxlife>} ></Route>
        <Route path='/login' element={<Login/>} ></Route>
    </Routes>
  )
}
