import React from 'react'
import { useNavigate } from 'react-router-dom'
import {BiLogOut} from 'react-icons/bi'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';


export const Navbar = () => {

  const nav = useNavigate()
  const handleLogout = ()=>{
    localStorage.removeItem('chatLogin');
    toast.error('Logout!');
    setTimeout(() => {
        nav('/login');
    }, 3000);
  }
  return (
    <>
      <div className='h-[80px] flex justify-between items-center p-[20px] relative ' >
        <div className='h-[25px] laptop:h-[40px] ' >
          <img className='w-[100%] h-[100%] object-contain ' src={'/PersonatewhiteLogo.png'} alt="Personate chatbot logo" />
        </div>

        <div
          onClick={handleLogout}
          className='text-[22px] laptop:text-[24px] mb-[10px] cursor-pointer text-white'
          data-tooltip-id="logoutTooltip"
        >
          <BiLogOut />
        </div>

        {/* Tooltip container */}
        <Tooltip
          id="logoutTooltip" // Match the ID
          place="bottom"
          type="dark"
          effect="solid"
          className="custom-tooltip"
        >
          Logout
        </Tooltip>
      
      </div>
    <ToastContainer/>
    </>
  )
}
